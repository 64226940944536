.slider.slider-horizontal{
    width:200px !important;
    }
    .d-block{
        width: 100% !important
    }

    @media screen and (max-width: 769px) {
.responsive{

}
.virtual{
    margin-right: "20px";
}

    }